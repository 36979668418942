import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";
import * as React from "react"
import { DialogActions, Dialog, DialogTitle, Button, DialogContent, CircularProgress } from '@mui/material'
import { green } from "@mui/material/colors";
//firestore
import { db } from "../../../firebase"
import { collection, onSnapshot } from "firebase/firestore";
import { format, parse } from "date-fns";
import getCurrentUserSubscription from "subscription";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import sendMail from "smtp";
import convertUTCtoLocal from "./../../../convertUTCtoLocal";

function Data() {

  const [data, setData] = React.useState([])
  const [initialData, setInitialData] = React.useState([]);
  const messageText = "Please upgrade your plan";

  const [loading, setLoading] = React.useState(false);
  const [isShowProgressLoader, setIsShowProgressLoader] = React.useState(false);

  const [brandsNotification, setBrandsNotification] = React.useState(false);
  const brandsNotificationClose = () => setBrandsNotification(false);

  const [userTokens, setUserTokens] = React.useState([]);

  const [userEmails, setUserEmails] = React.useState([]);

  const [messageContent, setMessageContent] = React.useState(messageText);

  const [sendMessageAlert, setSendMessageAlert] = React.useState(false);
  const sendMessageAlertOpen = () => setSendMessageAlert(true);
  const sendMessageAlertClose = () => setSendMessageAlert(false);


  const [isSubscriptionDone, setIsSubscriptionDone] = React.useState(false);

  const [selectedUsers, setSelectedUsers] = React.useState([])

  const [upgradeMessageParams, setUpgradeMessageParams] = React.useState({
    title: "",
    body: messageText
  });

  const [filter, setFilter] = React.useState({
    country: "",
    userType: ""
  })

  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Ivory Coast",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Korea",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe"
  ];

  const compareTimestamps = (a, b) => {
    const timestampA = new Date(a.timestamp);
    const timestampB = new Date(b.timestamp);
    return timestampB - timestampA;
  };

  React.useEffect(() => {
    setSelectedUsers([])
    if (initialData.length > 0) {
      const filterData = initialData.filter((item) => {
        var isCountryExist = !filter.country || item.locationText.toLowerCase().includes(filter.country.toLowerCase());
        var isUserTypeExist = !filter.userType || (
          filter.userType == "Subscribed" ? item.subscriptionData != null : item.subscriptionData == null
        )
        return isCountryExist && isUserTypeExist;
      })
      setData(filterData)
    }
  }, [filter])

  const getSubscriptionDetails = async () => {
    setIsSubscriptionDone(true);
    for(var index = 0; index < data.length; index++) {
      var subscriptionData = await getCurrentUserSubscription(data[index].id)
      setData(prevItems => {
        return prevItems.map((item, i) => {
          if (item.id === data[index].id) {
            return { ...item, isLoader: false, subscriptionData: subscriptionData };
          }
          return item;
        });
      });
    }
  }

  React.useEffect(() => {
    if (!isSubscriptionDone && data.length > 0) {
      getSubscriptionDetails();
    }
  }, [data])

  React.useEffect(() => {
    console.log("useEffect")
    setIsShowProgressLoader(true);
    setIsSubscriptionDone(false);
    const fetchData = onSnapshot(collection(db, "users"),
      async (snapshot) => {
        var brandList = [];
        for (var index = 0; index < snapshot.docs.length; index++) {
          var items = snapshot.docs[index];
          const rowItem = items.data();
          if (rowItem.isProfileComplete) {
            const itemData = items.data();
            if (itemData.timestamp == undefined && itemData.timestamp == null) {
              itemData.timestamp = new Date().toISOString().slice(0, -1).replace('T', ' ')
            }
            // var subscriptionData = await getCurrentUserSubscription(items.id);
            var subscriptionData = null;
            itemData.subscriptionData = subscriptionData;
            itemData.isLoader = true;
            brandList.push({ id: items.id, ...itemData })
          }
        }
        brandList.sort(compareTimestamps);
        setData(brandList)
        setInitialData(brandList)
        setIsShowProgressLoader(false);
        if (brandList.length == 0) {
          setIsSubscriptionDone(true);
        }
      },
      (error) => {
        console.log("error == ", error)
        setIsShowProgressLoader(false);
      })
    return () => {
      if (!isShowProgressLoader) {
        fetchData()
      }
    }
  }, [])

  const SR_NO = ({ srNo }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography variant="body2" fontWeight="small">
          {srNo}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
  const FIELD_NAME = ({ name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          <span style={{ fontWeight: '400', fontSize: '14px' }}>{name}</span>
        </MDTypography>
      </MDBox >
    </MDBox>
  );

  const SUBSCRIPTION = ({ item }) => {
    if (item.subscriptionData != null) {
      if (item.subscriptionData.plan.id == "Platinum") {
        return <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDBox ml={2} lineHeight={1}>
            <MDTypography display="block" variant="button" fontWeight="medium">
              <span style={{ fontWeight: '400', fontSize: '14px', textAlign: 'center', color: 'green' }}>
                {item.subscriptionData.plan.id} <br></br>
                {item.subscriptionData.plan.duration} <br></br>
                {format(new Date(item.subscriptionData.expireTimestamp), 'd MMMM yyyy h:mm a')}
              </span>
            </MDTypography>
          </MDBox >
        </MDBox>
      } else {
        return <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDBox ml={2} lineHeight={1}>
            <MDTypography display="block" variant="button" fontWeight="medium">
              <span style={{ fontWeight: '400', fontSize: '14px', textAlign: 'center', color: 'green' }}>
                {item.subscriptionData.plan.id} <br></br>
                {item.subscriptionData.plan.duration} <br></br>
                {format(new Date(item.subscriptionData.expireTimestamp), 'd MMMM yyyy h:mm a')}
              </span>
            </MDTypography>
            <MDButton variant="gradient" color="info" size="small" onClick={() => {
              setSendMessageAlert(true);
              setUserTokens([item.token]);
              setUserEmails([item.email])
              setUpgradeMessageParams({
                title: '',
                body: messageText
              })
            }} >Upgrade</MDButton>
          </MDBox >
        </MDBox>
      }
    } else {
      return <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDBox ml={2} lineHeight={1}>
          <MDButton variant="gradient" color="info" size="small" onClick={() => {
            setSendMessageAlert(true);
            setUserTokens([item.token]);
            setUserEmails([item.email])
            setUpgradeMessageParams({
              title: '',
              body: messageText
            })
          }}>Upgrade</MDButton>
        </MDBox >
      </MDBox>
    }
  }

  const sendMessage = (notificationParams) => {
    setLoading(true)
    fetch('https://fcm.googleapis.com/fcm/send', {
      method: 'POST',
      headers: {
        'Authorization': 'key=AAAAYjTniIQ:APA91bGbdvzwydRKLwKNvveCCiKsl5Fa4gQccZha5-GwYiRzwE3E-mM0GZbe6xH64QuVBAf_GtLKh73IMM6OJ0IyTalqCLNd7bSPgtqp2Mwes1UeTZlshQPeHEfM5_z3NoKUZD-qKIpz',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        notification: notificationParams,
        registration_ids: userTokens
      })
    })
      .then(response => response.json())
      .then(async (data) => {
        await sendMail(userEmails, upgradeMessageParams.title, upgradeMessageParams.body)
        setUpgradeMessageParams({
          title: "",
          body: messageText
        })
        setBrandsNotification(true)
        sendMessageAlertClose(false)
        setSelectedUsers([]);
        setUserTokens([])
        setUserEmails([])
        setLoading(false)
      })
      .catch((error) => {
        setUpgradeMessageParams({
          title: "",
          body: messageText
        })
        sendMessageAlertClose(false)
        setSelectedUsers([]);
        setUserTokens([])
        setUserEmails([])
        setLoading(false)
      });
  }

  const selectAll = () => {
    if (selectedUsers.length == data.length) {
      setSelectedUsers([])
    } else {
      const ids = data.map((item) => item.id);
      setSelectedUsers(ids)
    }
  }

  const selectItem = (item) => {
    var allUsers = [...selectedUsers];
    if (allUsers.includes(item.id)) {
      allUsers = allUsers.filter((id) => id != item.id)
    } else {
      allUsers.push(item.id)
    }
    setSelectedUsers(allUsers)
  }

  return {
    progressLoader: setIsShowProgressLoader ? <div className="loader-container">
      <div className="loader"></div>
    </div> : <></>,
    buttons: <div>
      <MDButton disabled={selectedUsers.length == 0} onClick={() => {
        setSendMessageAlert(true);
        setUpgradeMessageParams({
          title: '',
          body: ''
        })
        const tokens = []
        const emails = [];
        selectedUsers.map((userId) => {
          const userItem = data.filter((user) => user.id == userId);
          if (userItem.length > 0) {
            tokens.push(userItem[0].token)
            emails.push(userItem[0].email)
          }
        })
        setUserTokens(tokens);
        setUserEmails(emails);
      }} variant="gradient" color="info" size="small">Send Notification</MDButton>
    </div>,
    fillters: <div>
      <select
        style={{
          padding: '3px',
          borderRadius: '12px'
        }} onChange={(e) => {
          setFilter({
            ...filter,
            country: e.target.value
          })
        }}>
        <option value="">Select Country</option>
        {countries.map((item) => {
          return (
            <option value={item}>{item}</option>
          )
        })}
      </select> &nbsp;&nbsp;
      <select
        style={{
          padding: '3px',
          borderRadius: '12px'
        }} onChange={(e) => {
          setFilter({
            ...filter,
            userType: e.target.value
          })
        }}>
        <option value="">User Type</option>
        <option value="Subscribed">Subscribed</option>
        <option value="Unsubscribed">Unsubscribed</option>
      </select>
    </div>,
    columns: [
      { Header: <input onChange={selectAll} checked={selectedUsers.length > 0 && selectedUsers.length == data.length} type='checkbox' />, accessor: "srNo", width: '10%', align: "center" },
      { Header: "Name", accessor: "name", align: "center" },
      { Header: "Email", accessor: "email", align: "center" },
      { Header: "DOB", accessor: "dob", align: "center" },
      { Header: "Location", accessor: "location", align: "center" },
      { Header: "Subscription", accessor: "subscription", align: "center" },
      { Header: "Created Date", accessor: "timestamp", align: "center" },
      { Header: "Action", accessor: "action", align: "center" }
    ],
    rows: [...data.map((items, index) => {
      return ({
        srNo: <input checked={selectedUsers.includes(items.id)} onChange={() => selectItem(items)} style={{ marginLeft: '16px' }} type="checkbox" />,
        name: <FIELD_NAME name={items.name} />,
        email: <FIELD_NAME name={items.email} />,
        dob: <FIELD_NAME name={format(parse(items.dob, 'd/M/yyyy', new Date()), 'do MMMM yyyy')} />,
        location: <FIELD_NAME name={items.locationText} />,
        subscription: <div>
          <MDSnackbar
            color="success"
            icon="check"
            title="Message Sent Successfully"
            open={brandsNotification}
            onClose={brandsNotificationClose}
            close={brandsNotificationClose}
          />
          <Dialog
            open={sendMessageAlert}
            onClose={sendMessageAlertClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            // sx={{
            //   '& .MuiBackdrop-root': {
            //     backgroundColor: 'rgba(0, 0, 0, 0.1)', // Example background color
            //   }
            // }}
          >
            <DialogTitle id="alert-dialog-title">
              {"Send Notification"}
            </DialogTitle>
            <DialogContent>
              <div>
                <MDInput
                  mt={2}
                  mb={2}
                  sx={{
                    width: '100%',
                    marginBottom: '20px'
                  }}
                  focused
                  onChange={(e) => {
                    setUpgradeMessageParams({
                      ...upgradeMessageParams,
                      title: e.target.value
                    })
                  }}
                  placeholder="Enter title"
                />
              </div>
              <div>
                <MDInput
                  mt={2}
                  mb={2}
                  sx={{
                    width: '450px'
                  }}
                  multiline
                  focused
                  rows={3}
                  value={upgradeMessageParams.body}
                  onChange={(e) => {
                    setUpgradeMessageParams({
                      ...upgradeMessageParams,
                      body: e.target.value
                    })
                  }}
                  placeholder="Enter your message"
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button sx={{ color: 'error.main' }} onClick={sendMessageAlertClose}>
                Cancel
              </Button>
              {loading ?
                <CircularProgress
                  size={30}
                  sx={{
                    color: green[500],
                  }}
                /> :
                upgradeMessageParams.title && upgradeMessageParams.body && (
                  <Button onClick={() => { sendMessage(upgradeMessageParams) }}>Send</Button>
                )
              }
            </DialogActions>
          </Dialog>

          <SUBSCRIPTION item={items} />

        </div>,
        timestamp: <FIELD_NAME name={
          items.timestamp ?
            format(convertUTCtoLocal(items.timestamp), 'do MMMM yyyy, hh:mm:ss a') : ''
        } />,
        action: <div>
          <Link to={`/admin/users/detail/${items.id}`}><MDButton variant="gradient" color="info" size="small">Detail</MDButton></Link> &nbsp;&nbsp;&nbsp;
        </div>,
      })
    })]
  }
}
export default Data