
import { db } from "./firebase";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";

const getCurrentUserSubscription = async (userId) => {

    const q = query(
        collection(db, 'subscriptions'),
        where('userId', '==', userId)
    );
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    if (data && data.length > 0) {
        data.sort(function(a, b) {
            return new Date(b.timestamp) - new Date(a.timestamp);
        });
        var currentTimestamp = Date.now();
        // Provided expiration timestamp
        var expireTimestamp = new Date(data[0].expireTimestamp).getTime();
        // Check if expireTimestamp is greater than currentTimestamp
        if (expireTimestamp > currentTimestamp) {
            return data[0];
        } else {
            return null;
        }
    } else {
        return null;
    }
}

export default getCurrentUserSubscription;