const allRights = [
    {
        value: "UPDATE_SETTING", label: "Update Setting"
    },
    {
        value: "ADD_NEWS_ARTICLES", label: "Add News Article"
    },
    {
        value: "DELETE_NEWS_ARTICLES", label: "Delete News Article"
    },
    {
        value: "ADD_LAW", label: "Add Law"
    },
    {
        value: "DELETE_LAW", label: "Delete Law"
    },
    {
        value: "UPDATE_LAW", label: "Update Law"
    },
    {
        value: "RESTRICT_USER", label: "Restrict User"
    },
    {
        value: "SEND_MESSAGE", label: "Send Message"
    }
];


export default allRights;