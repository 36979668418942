// @mui material components
import Grid from "@mui/material/Grid";

// Admin panel React components
import MDBox from "components/MDBox"
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { CircularProgress, OutlinedInput, InputAdornment, IconButton, DialogActions, Dialog, DialogTitle, DialogContent, Typography, Box, TextField, InputLabel, FormControl, Select, MenuItem } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';

// Admin panel React example components
import * as React from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDSnackbar from "components/MDSnackbar";

// Data
import banksNameTable from "layouts/banks/data/banksNameTable";

//firestore 
import { db, storage } from "../../firebase"
import { collection, addDoc, doc, setDoc, serverTimestamp, onSnapshot } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { green } from "@mui/material/colors";
import userHasRight from "constant";
import sendMail from "smtp";
import getCurrentUserSubscription from "subscription";

//modal Styles
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function Banks() {
  const [data, setData] = React.useState([])

  const [userTokens, setUserTokens] = React.useState([]);

  const [userEmails, setUserEmails] = React.useState([]);

  const { columns, rows } = banksNameTable();
  const [bankModal, setBankModal] = React.useState(false);
  const [bankNotification, setBankNotification] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [imageProgress, setImageProgress] = React.useState(0);
  const [imageProgressValue, setImageProgressValue] = React.useState(0);
  const [bankFile, setBankFile] = React.useState('')

  const [userData, setUserData] = React.useState([]);

  const [articleData, setArticleData] = React.useState({
    title: '',
    content: ''
  })

  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    const fetchData = onSnapshot(collection(db, "users"),
      async (snapshot) => {
        var brandList = [];
        for (var index = 0; index < snapshot.docs.length; index++) {
          var items = snapshot.docs[index];
          const rowItem = items.data();
          if (rowItem.isProfileComplete) {
            const itemData = items.data();
            if (itemData.timestamp == undefined && itemData.timestamp == null) {
              itemData.timestamp = new Date().toISOString().slice(0, -1).replace('T', ' ')
            }
            brandList.push({ id: items.id, ...itemData })
          }
        }
        setData(brandList)

        // const tokens = []
        // const emails = [];
        // brandList.map((item) => {
        //   tokens.push(item.token)
        //   emails.push(item.email)
        // })
        // setUserTokens(tokens);
        // setUserEmails(emails);
      },
      (error) => {
        console.log("error == ", error)
      })
    return () => {
      fetchData()
    }
  }, [])

  const sendMessage = (upgradeMessageParams) => {
    console.log(userTokens);
    fetch('https://fcm.googleapis.com/fcm/send', {
      method: 'POST',
      headers: {
        'Authorization': 'key=AAAAYjTniIQ:APA91bGbdvzwydRKLwKNvveCCiKsl5Fa4gQccZha5-GwYiRzwE3E-mM0GZbe6xH64QuVBAf_GtLKh73IMM6OJ0IyTalqCLNd7bSPgtqp2Mwes1UeTZlshQPeHEfM5_z3NoKUZD-qKIpz',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        notification: upgradeMessageParams,
        registration_ids: userTokens
      })
    })
      .then(response => response.json())
      .then(async (data) => {
        await sendMail(userEmails, upgradeMessageParams.title, upgradeMessageParams.body)
      })
      .catch((error) => {
      });
  }

  const onAddArticle = async (e) => {
    e.preventDefault()
    //post data into firestore
    try {
      setLoading(true)
      const docId = await addDoc(collection(db, "articles"), {
        title: articleData.title,
        content: articleData.content,
        timestamp: serverTimestamp()
      })
      const updateData = {
        uid: docId.id
      }
      const DocRef = doc(db, "articles", docId.id)
      await setDoc(DocRef, updateData, { merge: true })
      bankModalClose()
      bankNotificationOpen()


      sendMessage({
        title: articleData.title,
        body: articleData.content
      })

      setArticleData({
        title: '',
        content: '',
      })
      setImageProgress(0)
      setImageProgressValue(0)
    }
    catch (error) {
      setError(error.code)
      setLoading(false)
    }
  }

  const bankModalOpen = () => setBankModal(true);
  const bankModalClose = () => {
    setBankModal(false)
    setLoading(false)
    setError('')
    setImageProgress(0)
    setImageProgressValue(0)
    setUserTokens([]);
    setUserEmails([]);
    setSelectedOptions([]);
  };
  const bankNotificationOpen = () => setBankNotification(true);
  const bankNotificationClose = () => setBankNotification(false);

  const [selectedOptions, setSelectedOptions] = React.useState([]);

  const handleChange = (event) => {
    const users = event.target.value;
    setSelectedOptions(users);
    setUserTokens(users.length == 0 ? [] : users.map((item) => item.token))
    setUserEmails(users.length == 0 ? [] : users.map((item) => item.email))
    handleClose()
  };

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };


  return (
    <>
      <MDSnackbar
        color="success"
        icon="check"
        title="News and Article added Successfully"
        open={bankNotification}
        onClose={bankNotificationClose}
        close={bankNotificationClose}
      />
      <BootstrapDialog
        onClose={bankModalClose}
        aria-labelledby="customized-dialog-title"
        open={bankModal}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={bankModalClose}>
          <Typography variant="h3" color="secondary.main" sx={{ pt: 1, textAlign: "center" }}>Add News and Article</Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 2, width: '500px', maxWidth: "100%", display: "flex", direction: "column", justifyContent: "center" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              label="Title"
              type="text"
              rows={1}
              color="secondary"
              required
              value={articleData.title}
              onChange={(e) => setArticleData({
                ...articleData,
                title: e.target.value
              })}
            />
            <TextField
              label="Content"
              type="text"
              multiline
              rows={5}
              color="secondary"
              required
              value={articleData.content}
              onChange={(e) => setArticleData({
                ...articleData,
                content: e.target.value
              })} />

            <div style={{ width: '100%', padding: '3px 15px' }}>
              <Select
                labelId="multi-select-label"
                id="multi-select"
                style={{ width: '100%', height: '2.5rem' }}
                placeholder="Select Users"
                multiple
                displayEmpty
                value={selectedOptions}
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                onChange={(e) => {
                  handleChange(e);
                }}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <p>Select Users</p>;
                  }
                  return (selected.map((item) => item.name)).join(', ')
                }}
              >
                <MenuItem disabled value="">
                  <p>Select Users</p>
                </MenuItem>
                {
                  data.map((item) => {
                    return <MenuItem value={item}>{item.name}</MenuItem>
                  })
                }
              </Select>
            </div>
            {error === '' ? null :
              <MDBox mb={2} p={1}>
                <TextField
                  error
                  id="standard-error"
                  label="Error"
                  InputProps={{
                    readOnly: true,
                    sx: {
                      "& input": {
                        color: "red",
                      }
                    }
                  }}
                  value={error}
                  variant="standard"
                />
              </MDBox>}
          </Box>
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'center' }}>
          {loading ?
            <CircularProgress
              size={30}
              sx={{
                color: green[500],
              }}
            /> : <MDButton variant="contained" color="info" type="submit"
              disabled={articleData.title === '' || articleData.content === '' ? true : false}
              onClick={onAddArticle}
            >Save</MDButton>
          }
        </DialogActions>
      </BootstrapDialog>

      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={2}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDBox display="flex" justifyContent="space-between" alignItems="center">
                      <MDTypography variant="h6" fontWeight="medium" color="white">
                        All News And Articles
                      </MDTypography>
                      {
                        userHasRight('ADD_NEWS_ARTICLES') == true && (
                          <MDButton variant="gradient" color="light"
                            onClick={() => {
                              bankModalOpen()
                            }}>
                            <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                            &nbsp;ADD NEWS And ARTICLES
                          </MDButton>
                        )
                      }

                    </MDBox>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default Banks;
