import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom"
import * as React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { Card, CardMedia, Modal, CircularProgress, OutlinedInput, DialogContentText, InputAdornment, IconButton, DialogActions, Dialog, DialogTitle, Button, DialogContent, Typography, Box, TextField, InputLabel, MenuItem, FormControl, Select, TableRow, Table, TableHead, TableCell, TableContainer, Paper, TableBody } from '@mui/material'
import { green } from "@mui/material/colors";
import CheckIcon from '@mui/icons-material/Check';

// Admin panel React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDAvatar from "components/MDAvatar";

// Admin panel React context
import { useMaterialUIController } from "context";

//firestore
import { db, storage } from "../../../firebase"
import { doc, deleteDoc, getDoc, collection, query, where, getDocs, setDoc, arrayUnion, arrayRemove, orderBy, updateDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import MDInput from "components/MDInput";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";

import { format } from 'date-fns';
import userHasRight from "constant";
import getCurrentUserSubscription from "subscription";
import sendMail from "smtp";


//modal Styles
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

//Modal Style
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '1rem',
  boxShadow: 24,
};
function Bill({ name, contactNo, website, category, logo, noGutter, dataId }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [sendMessageAlert, setSendMessageAlert] = React.useState(false);
  const [brandsNotification, setBrandsNotification] = React.useState(false);
  const [userData, setUserData] = React.useState(null)
  const [userProfileImage, setUserProfileImage] = React.useState(null)
  const navigate = useNavigate()

  const [supportRequestsModal, setSupportRequestsModal] = React.useState(false);
  const [supportRequestsData, setSupportRequestsData] = React.useState(null);

  const [userSubscriptions, setUserSubscriptions] = React.useState(null);


  const [messageParams, setMessageParams] = React.useState({
    title: "",
    body: ""
  });

  const [showMatchedUsers, setShowMatchedUsers] = React.useState(false);


  const [showBlockedUsers, setShowBlockedUsers] = React.useState(false);

  const [macthedUserData, setMacthedUserData] = React.useState(null)

  const [blockedUserData, setBlockedUserData] = React.useState(null)

  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '20px',
    fontFamily: 'Arial, sans-serif',
    fontSize: '13px'
  };

  const thStyle = {
    backgroundColor: '#f2f2f2',
    padding: '10px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
  };

  const tdStyle = {
    padding: '10px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
  };

  const evenRowStyle = {
    backgroundColor: '#f9f9f9',
  };

  const hoverRowStyle = {
    backgroundColor: '#f2f2f2',
  };

  async function fetchUser(id) {
    try {
      const getUsers = await getDoc(doc(db, "users", id));
      if (getUsers.exists()) {
        return getUsers.data();
      } else {
        return null;
      }
    } catch (error) {
      console.log('error == ', error)
      return null;
    }
  }

  const fetchMacthedDataById = async (dataId) => {
    try {
      const q = query(
        collection(db, 'likes'),
        where('userIds', 'array-contains', dataId)
      );
      const querySnapshot = await getDocs(q);
      const macthedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      if (macthedData) {
        var ids = [];
        for (var index = 0; index < macthedData.length; index++) {
          if (macthedData[index].isMatched && !macthedData[index].isDeleted && !macthedData[index].directMatch) {
            var userIds = macthedData[index].userIds
            let i = userIds.indexOf(dataId);
            if (i !== -1) {
              userIds.splice(i, 1);
              if (userIds.length > 0) {
                ids.push(userIds[0])
              }
            }
          }
        }

        var userDatas = []
        for (var j = 0; j < ids.length; j++) {
          var returnUser = await fetchUser(ids[j]);
          if (returnUser) {
            userDatas.push(returnUser)
          }
        }
        setMacthedUserData(userDatas)


      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.log('error == ', error)
    }
  }

  const fetchBlockedDataById = async (dataId) => {
    try {
      const q = query(
        collection(db, 'blockedUsers'),
        where('blockTo', '==', dataId)
      );
      const querySnapshot = await getDocs(q);
      const blockedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));


      if (blockedData) {
        var ids = [];
        for (var index = 0; index < blockedData.length; index++) {
          ids.push(blockedData[index].blockBy)
        }

        var userDatas = []
        for (var j = 0; j < ids.length; j++) {
          var returnUser = await fetchUser(ids[j]);
          if (returnUser) {
            userDatas.push(returnUser)
          }
        }
        setBlockedUserData(userDatas)
      } else {
        console.log("No such document!");
        setBlockedUserData([])
      }
    } catch (error) {
      console.log('error == ', error)
    }
  }

  const fetchDataById = async (dataId) => {
    // get data from firestore
    try {
      const getUsers = await getDoc(doc(db, "users", dataId));
      if (getUsers.exists()) {
        setUserData(getUsers.data())
        var userLogo = getLogo(getUsers.data());
        setUserProfileImage(userLogo);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.log('error == ', error)
    }
  };

  const fetchSupportDataById = async (dataId) => {
    try {
      const q = query(
        collection(db, 'help_and_support'),
        where('userId', '==', dataId)
      );
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      if (data) {
        setSupportRequestsData(data)
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.log('error == ', error)
    }
  };

  const fetchUserSubscriptionById = async (dataId) => {
    try {
      var currentUserSubscription = await getCurrentUserSubscription(dataId);
      console.log("currentUserSubscription", currentUserSubscription)
      if (currentUserSubscription) {
        setUserSubscriptions(currentUserSubscription)
      }
    } catch (error) {
      console.log('error == ', error)
    }
  };

  React.useEffect(() => {
    fetchDataById(dataId)
    fetchSupportDataById(dataId)
    fetchUserSubscriptionById(dataId)
    fetchMacthedDataById(dataId)
    fetchBlockedDataById(dataId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataId])

  React.useEffect(() => {
  }, [dataId])

  const sendMessageAlertOpen = () => setSendMessageAlert(true);
  const sendMessageAlertClose = () => setSendMessageAlert(false);

  const supportRequestsModalOpen = () => setSupportRequestsModal(true);
  const supportRequestsModalClose = () => setSupportRequestsModal(false);

  const showMatchedUsersOpen = () => setShowMatchedUsers(true);
  const showMatchedUsersClose = () => setShowMatchedUsers(false);

  const showBlockedUsersOpen = () => setShowBlockedUsers(true);
  const showBlockedUsersClose = () => setShowBlockedUsers(false);

  const brandsNotificationClose = () => setBrandsNotification(false);

  const getLogo = (userProfile) => {
    if (userProfile != "" && userProfile != null) {
      if (userProfile.profileImage != undefined && userProfile.profileImage != "") {
        return userProfile.profileImage;
      } else {
        return userProfile.wayAlbum.length > 0 ? userProfile.wayAlbum[0] : 'https://img.freepik.com/premium-vector/avatar-profile-icon-vector-illustration_276184-165.jpg';
      }
    }
  }

  const sendMessage = (messageParams) => {
    fetch('https://fcm.googleapis.com/fcm/send', {
      method: 'POST',
      headers: {
        'Authorization': 'key=AAAAYjTniIQ:APA91bGbdvzwydRKLwKNvveCCiKsl5Fa4gQccZha5-GwYiRzwE3E-mM0GZbe6xH64QuVBAf_GtLKh73IMM6OJ0IyTalqCLNd7bSPgtqp2Mwes1UeTZlshQPeHEfM5_z3NoKUZD-qKIpz',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        notification: messageParams,
        to: userData.token
      })
    })
      .then(response => response.json())
      .then(async (data) => {

        await sendMail([userData.email], messageParams.title, messageParams.body)

        setMessageParams({
          body: "",
          title: ""
        })
        setBrandsNotification(true)
        sendMessageAlertClose(false)
      })
      .catch((error) => {
        setMessageParams({
          body: "",
          title: ""
        })
        sendMessageAlertClose(false)
      });
  }

  const [userStatusUpdateMessage, setUserStatusUpdateMessage] = React.useState("");
  const [userStatusUpdateNotification, setUserStatusUpdateNotification] = React.useState(false);


  const updateUser = (isApproved) => {
    updateDoc(doc(db, "users", dataId), {
      isApproved: isApproved
    })
      .then(() => {
        if (isApproved) {
          setUserStatusUpdateMessage("User Approved Succcesfully");
        } else {
          setUserStatusUpdateMessage("User Blocked Succcesfully");
        }
        setUserStatusUpdateNotification(true)

        fetchDataById(dataId)

      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
  }


  return (
    <>

      <MDSnackbar
        color="success"
        icon="check"
        title={userStatusUpdateMessage}
        // content="Hello, world! This is a brandsNotification message"
        // dateTime="11 mins ago"
        open={userStatusUpdateNotification}
        onClose={() => setUserStatusUpdateNotification(false)}
        close={() => setUserStatusUpdateNotification(false)}
      />


      <MDSnackbar
        color="success"
        icon="check"
        title="Message Sent Successfully"
        // content="Hello, world! This is a brandsNotification message"
        // dateTime="11 mins ago"
        open={brandsNotification}
        onClose={brandsNotificationClose}
        close={brandsNotificationClose}
      />
      <Dialog
        open={sendMessageAlert}
        onClose={sendMessageAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Send Notification"}
        </DialogTitle>
        <DialogContent>
          <div>
            <MDInput
              mt={2}
              mb={2}
              sx={{
                width: '100%',
                marginBottom: '20px'
              }}
              focused
              onChange={(e) => {
                setMessageParams({
                  ...messageParams,
                  title: e.target.value
                })
              }}
              placeholder="Enter title"
            />
          </div>
          <div>
            <MDInput
              mt={2}
              mb={2}
              sx={{
                width: '450px'
              }}
              multiline
              focused
              rows={3}
              onChange={(e) => {
                setMessageParams({
                  ...messageParams,
                  body: e.target.value
                })
              }}
              placeholder="Enter your message"
            />
          </div>
        </DialogContent>
        <DialogActions>

          <Button sx={{ color: 'error.main' }} onClick={sendMessageAlertClose}>
            Cancel
          </Button>
          {
            messageParams.title && messageParams.body && (
              <Button onClick={() => { sendMessage(messageParams) }}>Send</Button>
            )
          }

        </DialogActions>
      </Dialog>


      <Dialog
        open={supportRequestsModal}
        onClose={supportRequestsModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Support Requests"}
        </DialogTitle>
        <DialogContent>
          <table style={{ borderCollapse: 'collapse', width: '450px' }}>
            <thead>
              <tr style={{ border: '1px solid black' }}>
                <th style={{ border: '1px solid black', padding: '8px' }}>Serial No</th>
                <th style={{ border: '1px solid black', padding: '8px' }}>Message</th>
              </tr>
            </thead>
            <tbody>
              {supportRequestsData && supportRequestsData.map((row, index) => (
                <tr key={index} style={{ border: '1px solid black' }}>
                  <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>{index + 1}</td>
                  <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>{row.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: 'error.main' }} onClick={supportRequestsModalClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={showMatchedUsers}
        onClose={showMatchedUsersClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Matched Users"}
        </DialogTitle>
        <DialogContent>

          <table style={{ borderCollapse: 'collapse', width: '450px' }}>
            <thead>
              <tr style={{ border: '1px solid black' }}>
                <th style={{ border: '1px solid black', padding: '8px' }}>Sr. No.</th>
                <th style={{ border: '1px solid black', padding: '8px' }}>Name</th>
                <th style={{ border: '1px solid black', padding: '8px' }}>Email</th>
              </tr>
            </thead>
            <tbody>
              {macthedUserData && macthedUserData.map((row, index) => (
                <tr key={index} style={{ border: '1px solid black' }}>
                  <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>{index + 1}</td>
                  <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>{row.name}</td>
                  <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>{row.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: 'error.main' }} onClick={showMatchedUsersClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={showBlockedUsers}
        onClose={showBlockedUsersClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Blocked By Users"}
        </DialogTitle>
        <DialogContent>

          <table style={{ borderCollapse: 'collapse', width: '450px' }}>
            <thead>
              <tr style={{ border: '1px solid black' }}>
                <th style={{ border: '1px solid black', padding: '8px' }}>Sr. No.</th>
                <th style={{ border: '1px solid black', padding: '8px' }}>Name</th>
                <th style={{ border: '1px solid black', padding: '8px' }}>Email</th>
              </tr>
            </thead>
            <tbody>
              {macthedUserData && macthedUserData.map((row, index) => (
                <tr key={index} style={{ border: '1px solid black' }}>
                  <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>{index + 1}</td>
                  <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>{row.name}</td>
                  <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>{row.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: 'error.main' }} onClick={showBlockedUsersClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {
        userData && (
          <MDBox
            component="li"
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            bgColor={darkMode ? "transparent" : "grey-100"}
            borderRadius="lg"
            p={3}
            mb={noGutter ? 0 : 1}
            mt={2}
          >
            <MDBox width="100%" display="flex" flexDirection="column">
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
                mb={2}
              >
                <MDTypography variant="caption" color="text">
                  Name:&nbsp;&nbsp;&nbsp;
                  <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                    {userData.name}
                  </MDTypography>
                </MDTypography>
                <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
                  <MDBox mr={1}>
                    <div class="profile-photo">
                      <img alt="Profile Photo" src={userProfileImage} />
                    </div>
                  </MDBox>
                  {
                    userHasRight('RESTRICT_USER') == true && (
                      userData && (userData.isApproved != undefined && userData.isApproved != null && userData.isApproved == true) ? (
                        <MDBox mr={1}>
                          <MDButton variant="text" color="error" onClick={() => {
                            updateUser(false)
                            sendMessage({
                              title: 'Account Update',
                              body: 'Due to policy violation you have been blocked. Please email at contact@mifever.com for support.'
                            })
                          }}>
                            Block
                          </MDButton>
                        </MDBox>
                      ) : (
                        <MDBox mr={1}>
                          <MDButton variant="text" color={"success"} onClick={() => {
                            updateUser(true)
                            sendMessage({
                              title: 'Account Update',
                              body: 'Congratulations! Your profile has been approved. Welcome aboard!'
                            })
                          }}>
                            Approve
                          </MDButton>
                        </MDBox>
                      )
                    )
                  }

                  <MDButton variant="gradient" color="info" size="small" onClick={supportRequestsModalOpen}>Support Requests</MDButton> &nbsp;&nbsp;&nbsp;
                  <MDButton variant="gradient" color="info" size="small" onClick={showMatchedUsersOpen}>Matched Users</MDButton> &nbsp;&nbsp;&nbsp;
                  <MDButton variant="gradient" color="info" size="small" onClick={showBlockedUsersOpen}>Blocked By Users</MDButton> &nbsp;&nbsp;&nbsp;

                  {
                    userHasRight('SEND_MESSAGE') == true && (
                      <MDButton variant="gradient" color="info" size="small" onClick={sendMessageAlertOpen}>Send Notification</MDButton>
                    )
                  }

                </MDBox>
              </MDBox>

              <MDBox mt={2} mb={1} lineHeight={0}>
                <MDTypography variant="caption" fontWeight="medium">Basic Info</MDTypography>
              </MDBox>

              <table style={tableStyle}>
                <thead>
                  <tr>
                    <th style={thStyle}>Email</th>
                    <th style={thStyle}>Date of birth</th>
                    <th style={thStyle}>Gender</th>
                    <th style={thStyle}>Location</th>
                    <th style={thStyle}>Your Interest</th>
                    <th style={thStyle}>You are here for</th>
                    <th style={thStyle}>Available Locations</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={evenRowStyle}>
                    <td style={tdStyle}>{userData.email}</td>
                    <td style={tdStyle}>{userData.dob}</td>
                    <td style={tdStyle}>{userData.gender}</td>
                    <td style={tdStyle}>{userData.locationText}</td>
                    <td style={tdStyle}>{userData.interestList.join(", ")}</td>
                    <td style={tdStyle}>{userData.whatDoYouWant}</td>
                    <td style={tdStyle}>{userData.availableLocation.join(", ")}</td>
                  </tr>
                </tbody>
              </table>

              <MDBox mt={2} mb={1} lineHeight={0}>
                <MDTypography variant="caption" fontWeight="medium">About Us</MDTypography>
              </MDBox>

              <table style={tableStyle}>
                <thead>
                  <tr>
                    <th style={thStyle}>Things you like</th>
                    <th style={thStyle}>What kind of person you are</th>
                    <th style={thStyle}>Fav location</th>
                    <th style={thStyle}>About my Culture</th>
                    <th style={thStyle}>Hobbies and activity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={evenRowStyle}>
                    <td style={tdStyle}>{userData.aboutMe.thingsYouLike}</td>
                    <td style={tdStyle}>{userData.aboutMe.whatKindPerson}</td>
                    <td style={tdStyle}>{userData.aboutMe.favLocation}</td>
                    <td style={tdStyle}>{userData.aboutMe.aboutMyCulture}</td>
                    <td style={tdStyle}>{userData.aboutMe.hobbiesAndActivity}</td>
                  </tr>
                </tbody>
              </table>


              <MDBox mt={2} mb={1} lineHeight={0}>
                <MDTypography variant="caption" fontWeight="medium">My Way Album</MDTypography>
              </MDBox>
              <div className="image-grid">
                {userData.wayAlbum.map((imageUrl, index) => (
                  <div class="image-grid-item">
                    <img src={imageUrl} alt="Image 2" />
                  </div>
                ))}
              </div>

              <MDBox mt={2} mb={1} lineHeight={0}>
                <MDTypography variant="caption" fontWeight="medium">My Life Album</MDTypography>
              </MDBox>
              <div className="image-grid">
                {userData.lifeAlbum.map((imageUrl, index) => (
                  <div class="image-grid-item">
                    <img src={imageUrl} alt="Image 2" />
                  </div>
                ))}
              </div>

              <MDBox mt={2} mb={1} lineHeight={0}>
                <MDTypography variant="caption" fontWeight="medium">My Subscription</MDTypography>
              </MDBox>

              {
                userSubscriptions && userSubscriptions != null && (

                  <table style={tableStyle}>
                    <thead>
                      <tr>
                        <th style={thStyle}>Plan Type</th>
                        <th style={thStyle}>Plan Amount</th>
                        <th style={thStyle}>Plan Expiry</th>
                        <th style={thStyle}>Plan Duration</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={evenRowStyle}>
                        <td style={tdStyle}>{userSubscriptions.plan.id}</td>
                        <td style={tdStyle}>{userSubscriptions.plan.amount}</td>
                        <td style={tdStyle}>{format(new Date(userSubscriptions.expireTimestamp), 'd MMMM yyyy h:mm a')}</td>
                        <td style={tdStyle}>{userSubscriptions.plan.duration}</td>
                      </tr>
                    </tbody>
                  </table>
                )
              }

            </MDBox>
          </MDBox>
        )
      }


    </>
  );
}

// Setting default values for the props of Bill
Bill.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Bill
Bill.propTypes = {
  name: PropTypes.string.isRequired,
  contactNo: PropTypes.string.isRequired,
  website: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};

export default Bill;
