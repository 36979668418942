// @mui material components
import Grid from "@mui/material/Grid";

// Amdin panel React components
import MDBox from "components/MDBox";

// Amdin panel React example components
import * as React from 'react';

import { useNavigate } from 'react-router-dom';

import { CircularProgress } from '@mui/material';
import { green } from "@mui/material/colors";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Footer from "examples/Footer";

//firestore
import { db } from "../../firebase"
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";

// Data
import exportedObject2 from "layouts/dashboard/data/reportsLineChartData";

import "rsuite/dist/rsuite.min.css";
import { DatePicker, InputGroup } from 'rsuite';
import { Button } from '@mui/material';
import MDButton from "components/MDButton";
import { CSVLink } from 'react-csv';
import getCurrentUserSubscription from "subscription";
import { format } from "date-fns";
import { parse } from "date-fns";
import convertUTCtoLocal from "convertUTCtoLocal";

function Dashboard() {
  const [deviceTokenId, setDeviceTokenId] = React.useState('');
  const { sales, tasks } = exportedObject2;

  const [users, setUsers] = React.useState([])
  const [subscriedUsers, setSubscriedUsers] = React.useState([])
  const [supportRequests, setSupportRequests] = React.useState([])
  const [revenue, setRevenue] = React.useState(0)

  // Get the current year
  // const currentYear = new Date().getFullYear();
  // const startOfYear = new Date(currentYear, 0, 1);
  // const endOfYear = new Date(currentYear, 11, 31, 23, 59, 59, 999);
  // const startTimestamp = startOfYear.getTime();
  // const endTimestamp = endOfYear.getTime();

  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  const [csvData, setCsvData] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  const headers = [
    { label: 'Name', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Created On', key: 'createdOn' },
    { label: 'Subscirption', key: 'subscription' },
    { label: 'Subscription Validity', key: 'subscriptionValidity' },
    { label: 'Blocked By', key: 'blockedBy' },
    { label: 'Liked By', key: 'likedBy' },
    { label: 'UnLiked By', key: 'unLikedBy' },
    { label: 'Location', key: 'location' },
  ];

  const [salesData, setSalesData] = React.useState({
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: { label: "Mobile apps", data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] }
  });

  React.useEffect(() => {
    async function fetchData() {
      const getAllTokens = await getDocs(collection(db, "deviceTokens"));
      const dbTokenData = getAllTokens.docs.map((items) => ({ id: items.id, ...items.data() }))
      let tokenData = {}
      for (let i = 0; i < dbTokenData.length; i++) {
        Object.assign(tokenData, dbTokenData[i]);
      }
      setDeviceTokenId(tokenData.id)
    }
    fetchData();
  }, [deviceTokenId])

  async function fetchUser(id) {
    try {
      const getUsers = await getDoc(doc(db, "users", id));
      if (getUsers.exists()) {
        return getUsers.data();
      } else {
        return null;
      }
    } catch (error) {
      console.log('error == ', error)
      return null;
    }
  }

  const exportUsers = async () => {
    const getAllDocs = await getDocs(collection(db, "users"));
    const dbData = getAllDocs.docs.map((items) => ({ id: items.id, ...items.data() }))
    console.log(dbData.length)
    var allCsvUser = [];
    for (var index = 0; index < dbData.length; index++) {
      if (Date.parse(dbData[index].timestamp) >= startDate && Date.parse(dbData[index].timestamp) <= endDate && dbData[index].isProfileComplete) {

        var subscriptionData = await getCurrentUserSubscription(dbData[index].id);
        var blockedUsers = [];
        const q = query(
          collection(db, 'blockedUsers'),
          where('blockTo', '==', dbData[index].id)
        );
        const querySnapshot = await getDocs(q);
        const blockedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        if (blockedData) {
          var ids = [];
          for (var index1 = 0; index1 < blockedData.length; index1++) {
            ids.push(blockedData[index1].blockBy)
          }
          for (var j = 0; j < ids.length; j++) {
            var returnUser = await fetchUser(ids[j]);
            if (returnUser) {
              blockedUsers.push(returnUser.name)
            }
          }
        }

        var likedUsers = [];
        var disLikedUser = [];

        const q1 = query(
          collection(db, 'notifications'),
          where('notificationTo', '==', dbData[index].id)
        );
        const likedQuerySnapshot = await getDocs(q1);
        const likedData = likedQuerySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        for (var j = 0; j < likedData.length; j++) {
          if (likedData[j].type == 'Like') {
            var returnUser = await fetchUser(likedData[j].notificationBy);
            likedUsers.push(returnUser.name);
          } else if (likedData[j].type == 'DisLike') {
            var returnUser = await fetchUser(likedData[j].notificationBy);
            disLikedUser.push(returnUser.name);
          }
        }

        allCsvUser.push({
          name: dbData[index].name,
          email: dbData[index].email,
          createdOn: format(convertUTCtoLocal(dbData[index].timestamp), 'do MMMM yyyy, hh:mm:ss a'),
          subscription: subscriptionData != null ? subscriptionData.plan.id : "",
          subscriptionValidity: subscriptionData != null ? format(convertUTCtoLocal(subscriptionData.expireTimestamp), 'do MMMM yyyy, hh:mm:ss a') : "",
          blockedBy: blockedUsers.length > 0 ? blockedUsers.join(", ") : "",
          likedBy: likedUsers.length > 0 ? likedUsers.join(", ") : "",
          unLikedBy: disLikedUser.length > 0 ? disLikedUser.join(", ") : "",
          location: dbData[index].locationText
        })
      }


    }

    setCsvData(allCsvUser);

    setLoading(false);
  }

  const fetchAllUsers = async () => {
    try {
      const getAllDocs = await getDocs(collection(db, "users"));
      const dbData = getAllDocs.docs.map((items) => ({ id: items.id, ...items.data() }))
      const onlineUsers = []
      var allUsers = [];
      dbData.map((filterItems) => {
        if (filterItems.isProfileComplete) {
          const item = {
            id: filterItems.id,
            name: filterItems.name,
          };
          if (filterItems.lasOnline != undefined && filterItems.lasOnline != null) {
            const firebaseTimestampMillis = filterItems.lasOnline.seconds * 1000 + filterItems.lasOnline.nanoseconds / 1000000;
            const currentTimestampMillis = Date.now();
            const differenceInSeconds = (currentTimestampMillis - firebaseTimestampMillis) / 1000;
            const thresholdSeconds = 300;
            const isLessThanThreshold = differenceInSeconds < thresholdSeconds;
            if (isLessThanThreshold) {
              onlineUsers.push(item)
            }
          }
          allUsers.push(item)
          // return item
        }
      })
      setUsers(allUsers)
    } catch (error) {
      console.log('error == ', error)
    }
  };

  const fetchAllSubscriptions = async () => {

    const updatedSalesData = { ...salesData };

    try {
      const getAllDocs = await getDocs(collection(db, "subscriptions"));
      const dbData = getAllDocs.docs.map((items) => ({ id: items.id, ...items.data() }))
      var amount = 0;
      setSubscriedUsers(dbData)
      dbData.map((filterItems) => {
        const date = new Date(filterItems.timestamp);
        const monthIndex = date.getMonth();
        amount += parseInt(filterItems.plan.amount.trim().replace("$", ""))
        var tempAmount = parseInt(updatedSalesData.datasets.data[monthIndex]) + parseInt(filterItems.plan.amount.trim().replace("$", ""));
        updatedSalesData.datasets.data[monthIndex] = tempAmount;
      })
      setRevenue(amount)
      setSalesData(updatedSalesData);
    } catch (error) {
      console.log('error == ', error)
    }
  };

  const fetchAllSupportRequests = async () => {
    try {
      const getAllDocs = await getDocs(collection(db, "help_and_support"));
      const data = getAllDocs.docs.map((items) => ({ id: items.id, ...items.data() }))
      setSupportRequests(data)
    } catch (error) {
      console.log('error == ', error)
    }
  };

  React.useEffect(() => {
    fetchAllUsers()
    fetchAllSubscriptions();
    fetchAllSupportRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (startDate && endDate) {
      setLoading(true);
      exportUsers();
    }
  }, [startDate, endDate])

  const navigate = useNavigate()

  const handleClick = (route) => {
    navigate('/admin/users')
  };



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3} style={{ cursor: 'pointer' }} onClick={() => handleClick('/route1')}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Total Users"
                count={users.length}
              // percentage={{
              //   color: "success",
              //   amount: "+3%",
              //   label: "than last month",
              // }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Subscribed Users"
                count={subscriedUsers.length}
              // percentage={{
              //   color: "success",
              //   amount: "+3%",
              //   label: "than last month",
              // }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Revenue"
                count={revenue + "$"}
              // percentage={{
              //   color: "success",
              //   amount: "+1%",
              //   label: "than yesterday",
              // }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Report Requests"
                count={supportRequests.length}
              // percentage={{
              //   color: "success",
              //   amount: "",
              //   label: "Just updated",
              // }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>

          <Grid container spacing={3} style={{ marginBottom: '60px' }}>
            <Grid item xs={12} md={3} lg={12} style={{ textAlign: 'right' }}>
              {/* <DateRangePicker onChange={(dates) => {
              setStartDate(dates[0])
              setEndDate(dates[1]);

              setLoading(true);
              exportUsers();
              
            }} showOneCalendar /> */}

              <InputGroup style={{ width: '100%' }}>
                <DatePicker value={startDate} format="yyyy-MM-dd HH:mm:ss" onChange={(date) => {
                  if (endDate == null || Date.parse(endDate) > Date.parse(date)) {
                    setStartDate(date)
                  }
                }} block appearance="subtle" style={{ width: 230 }} />
                <InputGroup.Addon>to</InputGroup.Addon>
                <DatePicker value={endDate} format="yyyy-MM-dd HH:mm:ss" block appearance="subtle" style={{ width: 230 }} onChange={(date) => {
                  if (startDate == null || Date.parse(startDate) < Date.parse(date)) {
                    setEndDate(date)
                  }
                }} />
                &nbsp;&nbsp;&nbsp;

                {loading ?
                  <CircularProgress
                    size={30}
                    sx={{
                      color: green[500],
                    }}
                  /> : startDate != null && endDate != null ? <CSVLink data={csvData} headers={headers} filename={'report.csv'}>
                    <MDButton variant="gradient" color="info" size="small">Export to CSV</MDButton>
                  </CSVLink> : <MDButton disabled={true} variant="gradient" color="info" size="small">Export to CSV</MDButton>
                }

              </InputGroup>



            </Grid>
          </Grid>

          <Grid container spacing={3}>


            {/* <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={3}>
              <ReportsBarChart
                color="info"
                title="website views"
                description="Last Campaign Performance"
                date="campaign sent 2 days ago"
                chart={exportedObject }
              />
            </MDBox>
          </Grid> */}
            <Grid item xs={12} md={12} lg={12}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  // title="daily sales"
                  description={
                    // <>
                    //   (<strong>+15%</strong>) increase in today sales.
                    // </>

                    <>
                      Total Sales {revenue}$
                    </>

                  }
                  date="just now"
                  chart={salesData}
                />
              </MDBox>
            </Grid>
            {/* <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={3}>
              <ReportsLineChart
                color="dark"
                title="completed tasks"
                description="Last Campaign Performance"
                date="just updated"
                chart={tasks}
              />
            </MDBox>
          </Grid> */}
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
