// Admin panel React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import * as React from "react"

//firestore
import { db } from "../.../../../../firebase"
import { collection, deleteDoc, doc, onSnapshot } from "firebase/firestore";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon } from "@mui/material";
import userHasRight from "constant";

function Data() {
  const [data, setData] = React.useState([])

  const [deleteAlert, setDeleteAlert] = React.useState(false);
  const deleteAlertOpen = () => setDeleteAlert(true);
  const deleteAlertClose = () => setDeleteAlert(false);
  
  const [deleteItemId, setDeleteItemId] = React.useState(null)

  React.useEffect(() => {
    const fetchData = onSnapshot(collection(db, "articles"),
      (snapshot) => {
        const bankList = snapshot.docs.map((items) => {
          return { id: items.id, ...items.data() }
        })
        setData(bankList)
      },
      (error) => {
        console.log("error == ", error)
      })
    return () => {
      fetchData()
    }
  }, [])

  const SR_NO = ({ srNo }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography variant="body2" fontWeight="small">
          {srNo}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
  const FIELD_NAME = ({ name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          <span style={{fontWeight: '400', fontSize: '14px'}}>{name}</span>
        </MDTypography>
      </MDBox >
    </MDBox>
  );

  const deleteItem = async () => {
    try {
      const reference = doc(db, 'articles', deleteItemId)
      await deleteDoc(reference)
      deleteAlertClose();
      handleClick();
    } catch (error) {
      console.log('error == ', error)
    }
  }


  const handleClick = () => {
    const alertDiv = document.createElement('div');
    alertDiv.innerHTML = `
      <div style="
          position: fixed;
          top: 20px;
          right: 20px;
          background: rgba(0, 255, 0, 0.7);
          padding: 10px 20px;
          border-radius: 5px;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
          z-index: 9999;
      ">
        <p>Item Deleted Successfully.</p>
      </div>
    `;
    document.body.appendChild(alertDiv);

    // Automatically remove the alert after 3 seconds
    setTimeout(() => {
      document.body.removeChild(alertDiv);
    }, 3000);
  };

  return {
    columns: [
      { Header: "SR NO#", accessor: "srNo", width: '10%', align: "left" },
      { Header: "Title", accessor: "title", align: "left" },
      { Header: "Content", accessor: "content", align: "left" },
      { Header: "Action", accessor: "action", width: '10%', align: "right" }
    ],
    rows: [...data.map((items, index) => {
      return ({
        srNo: <SR_NO srNo={index + 1} />,
        title: <FIELD_NAME name={items.title} />,
        content: <FIELD_NAME name={items.content} />,
        action: <div>
          <Dialog
            open={deleteAlert}
            onClose={deleteAlertClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Delete Confirmation"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this item?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={deleteAlertClose} color="primary">
                Cancel
              </Button>
              <Button onClick={deleteItem} color="secondary" autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>

          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            <MDBox mr={1}>
              {
                userHasRight('DELETE_NEWS_ARTICLES') == true && (
                  <MDButton variant="gradient" color="error" onClick={() => {
                    setDeleteItemId(items.id);
                    deleteAlertOpen();
                  }} >
                    <Icon>delete</Icon>&nbsp;delete
                  </MDButton>
                )
              }
              
            </MDBox>
            {/* <MDButton variant="gradient" color="info">
              <Icon>edit</Icon>&nbsp;edit
            </MDButton> */}
          </MDBox>

        </div>

        ,
      })
    })]
  };
}
export default Data