// Amdin panel React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import MDButton from "components/MDButton";
import { styled } from '@mui/material/styles';
import * as React from "react"
import { useContext } from "react";
import { AuthContext } from "context/AuthContext";
import { useMaterialUIController } from "context";

//firestore
import { db } from "../../../firebase"
import { collection, onSnapshot, where, getDocs, query, doc, deleteDoc, setDoc, getDoc } from "firebase/firestore";
import MDSnackbar from "components/MDSnackbar";
import { Button, CircularProgress, OutlinedInput, InputAdornment, Icon, IconButton, DialogActions, Dialog, DialogTitle, DialogContent, DialogContentText, Typography, Box, TextField, InputLabel, FormControl, Select, MenuItem, Grid, Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { green } from "@mui/material/colors";
import MDInput from "components/MDInput";
import allRights from "rights";

//modal Styles
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function Data() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [data, setData] = React.useState([])
  const { currentUser, role } = useContext(AuthContext)
  const [brandName, setBrandName] = React.useState('')

  const [categoryModal, setCategoryModal] = React.useState(false);
  const [categoryValue, setCategoryValue] = React.useState('')

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [categoryNotification, setCategoryNotification] = React.useState(false);
  const categoryNotificationOpen = () => setCategoryNotification(true);
  const categoryNotificationClose = () => setCategoryNotification(false);

  const [deleteAlert, setDeleteAlert] = React.useState(false);
  const [deleteDataId, setDeleteDataId] = React.useState('');
  const [updateDataId, setUpdateDataId] = React.useState('');

  const fetchAllCollectionsCategories = async () => {
    // get data from database
    try {
      const getCategory = await getDoc(doc(db, "admins", updateDataId));
      if (getCategory.exists()) {
        const item = getCategory.data();

        setSelectedRigts(item.rights)
        setDbCategoryData({
          name: item.name,
          email: item.email,
          password: item.password,
          role: 'subadmin',
          rights: item.rights
        })
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.log('error == ', error)
    }
  };
  React.useEffect(() => {
    fetchAllCollectionsCategories()
  }, [updateDataId])

  const [dbCategoryData, setDbCategoryData] = React.useState({
    name: '',
    email: '',
    password: '',
    role: 'subadmin',
    rights: {}
  });

  const deleteAlertOpen = (items) => {
    setDeleteAlert(true)
    setDeleteDataId(items.id)
  }

  const deleteAlertClose = () => {
    setDeleteAlert(false)
    setDeleteDataId('')
  };
  const categoryModalOpen = (items) => {
    setCategoryModal(true)
    setUpdateDataId(items.id)
    setCategoryValue(items.country)
  };
  const categoryModalClose = () => {
    setCategoryModal(false)
    setUpdateDataId('')
    setCategoryValue('')
    setLoading(false)
    setError('')
  };

  const [selectedRigts, setSelectedRigts] = React.useState({}); // State to store checked items

  // Function to handle checkbox change
  const handleCheckboxChange = (event) => {
    setSelectedRigts({
      ...selectedRigts,
      [event.target.name]: event.target.checked // Update checked item
    });
  };

  React.useEffect(() => {
    const fetchData = onSnapshot(collection(db, "admins"),
      (snapshot) => {
        const saleList = snapshot.docs
          .filter(items => items.data().role != 'admin')
          .map((items) => {
            return { id: items.id, ...items.data() }
          })
        saleList.sort((a, b) => b.timestamp - a.timestamp);
        setData(saleList)
      },
      (error) => {
        console.log("error == ", error)
      })
    return () => {
      fetchData()
    }
  }, [])
  const SR_NO = ({ srNo }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography variant="body2" fontWeight="small">
          {srNo}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
  const LABEL = ({ title }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          <span style={{ fontWeight: '400', fontSize: '14px' }}>{title} </span>
        </MDTypography>
      </MDBox >
    </MDBox>
  );

  const toPascalCase = (str) => {
    return str != "" ? str.replace(/(\w)(\w*)/g,
      function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); }) : ""
  }

  const RIGHTS = ({ rights }) => {
    let adminRights = ""
    const keys = Object.keys(rights);

    const transformedKeys = keys.filter((key) => rights[key] == true).map(key => {
      const right = key.replaceAll("_", " ");
      return toPascalCase(right);
    });
    adminRights = transformedKeys.join(", ");

    return <div style={{ maxWidth: '250px' }}>
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDBox ml={2} lineHeight={1} >
          <MDTypography display="block" variant="button" fontWeight="medium">

            <span style={{ fontWeight: '400', fontSize: '14px' }}>{adminRights} </span>
          </MDTypography>
        </MDBox >
      </MDBox>
    </div>
  }

  const deleteById = async () => {
    setLoading(true)
    try {
      if (deleteDataId) {
        const reference = doc(db, 'admins', deleteDataId)
        await deleteDoc(reference)
      }
      deleteAlertClose()
      setDeleteDataId('')
    } catch (error) {
      console.log('error == ', error)
    } finally {
      setLoading(false)
    }
  };

  const onUpdateCategory = async (e) => {
    e.preventDefault()
    const updateData = {
      name: dbCategoryData.name,
      password: dbCategoryData.password,
      rights: selectedRigts
    }
    try {
      setLoading(true)
      if (updateDataId) {
        const categoryDocRef = doc(db, "admins", updateDataId)
        await setDoc(categoryDocRef, updateData, { merge: true })
        setDbCategoryData({
          name: "",
          email: "",
          password: "",
          role: 'subadmin',
          rights: {}
        })
      }
      categoryModalClose()
      categoryNotificationOpen()
      setUpdateDataId('')
    }
    catch (error) {
      setError(error.code)
      setLoading(false)
    }
  }

  return {
    columns: [
      { Header: "SR NO#", accessor: "srNo", width: '10%', align: "left" },
      { Header: "Name", accessor: "name", align: "left" },
      { Header: "Email", accessor: "email", align: "left" },
      { Header: "Password", accessor: "password", align: "left" },
      { Header: "Rights", accessor: "rigths", align: "left" },
      { Header: "Action", accessor: "action", align: "left" }
    ],
    rows: [...data.map((items, index) => {
      return ({
        srNo: <SR_NO srNo={index + 1} />,
        name: <LABEL title={items.name} />,
        email: <LABEL title={items.email} />,
        password: <LABEL title={items.password} />,
        rigths: <RIGHTS rights={items.rights} />,
        action: (<>
          <MDSnackbar
            color="success"
            icon="check"
            title="Successfully Update"
            open={categoryNotification}
            onClose={categoryNotificationClose}
            close={categoryNotificationClose}
          />
          <Dialog
            open={deleteAlert}
            onClose={deleteAlertClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Alert"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={deleteAlertClose}>Cancel</Button>
              {
                loading ?
                  <CircularProgress
                    size={30}
                    sx={{
                      color: green[500],
                    }}
                  /> : <Button sx={{ color: 'error.main' }} onClick={deleteById}>
                    Delete
                  </Button>
              }
            </DialogActions>
          </Dialog>


          <BootstrapDialog
            onClose={categoryModalClose}
            aria-labelledby="customized-dialog-title"
            open={categoryModal}
          >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={categoryModalClose}>
              <Typography variant="h3" color="secondary.main" sx={{ pt: 1, textAlign: "center" }}>Edit Sub-Admin</Typography>
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 2, maxWidth: "100%", display: "flex", direction: "column", justifyContent: "center" },
                }}
                noValidate
                autoComplete="off"
              >

                <div style={{ width: '500px' }}>
                  <MDBox mb={2}>
                    <MDInput
                      value={dbCategoryData.name}
                      onChange={(e) => setDbCategoryData({
                        ...dbCategoryData,
                        name: e.target.value
                      })}
                      type="name"
                      label="Name"
                      variant="standard"
                      autoComplete="off"
                      required
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      value={dbCategoryData.email}
                      onChange={(e) => setDbCategoryData({
                        ...dbCategoryData,
                        email: e.target.value
                      })}
                      type="email"
                      label="Email"
                      variant="standard"
                      autoComplete="off"
                      disabled
                      required
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      value={dbCategoryData.password}
                      onChange={(e) => setDbCategoryData({
                        ...dbCategoryData,
                        password: e.target.value
                      })}
                      type="password"
                      label="Password"
                      variant="standard"
                      autoComplete="off"
                      required />
                  </MDBox>

                  <MDBox m={2}>
                    <FormGroup>
                      <Grid container>
                        {allRights.map((right) => (
                          <Grid item key={right.value} xs={6}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectedRigts[right.value] || false} // Set checked state based on state
                                  onChange={handleCheckboxChange}
                                  name={right.value}
                                />
                              }
                              label={right.label}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </FormGroup>
                  </MDBox>
                </div>
                <MDBox mt={1} p={1} sx={{ display: 'flex', direction: 'row', justifyContent: "center" }}>
                  {loading ?
                    <CircularProgress
                      size={30}
                      sx={{
                        color: green[500],
                      }}
                    /> : <MDButton variant="contained" color="info" type="submit"
                      disabled={dbCategoryData.email == "" || dbCategoryData.password == "" || dbCategoryData.name == "" ? true : false}
                      onClick={onUpdateCategory}
                    >Update</MDButton>
                  }
                </MDBox>
              </Box>
            </DialogContent>
            <DialogActions>
            </DialogActions>
          </BootstrapDialog>

          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            <MDBox mr={1}>
              <MDButton variant="text" color="error" onClick={() => deleteAlertOpen(items)}>
                <Icon>delete</Icon>&nbsp;delete
              </MDButton>
            </MDBox>
            <MDButton variant="text" color={darkMode ? "white" : "dark"} onClick={() => categoryModalOpen(items)}>
              <Icon>edit</Icon>&nbsp;edit
            </MDButton>
          </MDBox></>),
      })
    })]
  }
}
export default Data