// @mui material components
import Grid from "@mui/material/Grid";

// Admin panel React components
import MDBox from "components/MDBox"
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import CloseIcon from '@mui/icons-material/Close';
import MDInput from "components/MDInput";
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { CircularProgress, TextField, OutlinedInput, InputAdornment, IconButton, DialogActions, Dialog, DialogTitle, DialogContent, Typography, Box, InputLabel, MenuItem, FormControl, Select, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import { green } from "@mui/material/colors";

// Admin panel React example components
import * as React from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import Footer from "examples/Footer";
import MDSnackbar from "components/MDSnackbar";
import { useContext } from "react";
import { AuthContext } from "context/AuthContext";

// Data
import salesNameTable from "layouts/addSale/data/SalesNameTable";


//firestore
import { db } from "../../firebase"
import { collection, doc, addDoc, getDoc, getDocs, where, query, Timestamp } from "firebase/firestore";
import allRights from "rights";

//modal Styles
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function AddSale() {
  const { columns, rows } = salesNameTable();
  const { currentUser, role } = useContext(AuthContext)
  const [brandsSaleModal, setBrandsModal] = React.useState(false);
  const [brandsSaleNotification, setBrandsSaleNotification] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  const [errorModal, setErrorModal] = React.useState(false);
  const [signupCustomError, setSignupCustomError] = React.useState("")
  const errorModalClose = () => {
    setErrorModal(false)
    setSignupCustomError('')
  };

  const [selectedBrand, setSelectedBrand] = React.useState('');
  const [selectedRigts, setSelectedRigts] = React.useState({}); // State to store checked items

  // Function to handle checkbox change
  const handleCheckboxChange = (event) => {
    setSelectedRigts({
      ...selectedRigts,
      [event.target.name]: event.target.checked // Update checked item
    });
  };

  const [signupUser, setSignupUser] = React.useState({
    name: '',
    email: '',
    password: '',
    role: 'subadmin',
    rights: {}
  })

  const fetchAllBrands = async (selectedBrand) => {
    // get data from database
    const getAllDocs = await getDocs(collection(db, "admins"));
    const dbData = getAllDocs.docs.map((items) => ({ id: items.id, ...items.data() }))
    let allBrands = dbData.map((filterItems) => {
      return {
        id: filterItems.id,
        name: filterItems.name,
      }
    })

  };
  React.useEffect(() => {
    role === "admin" && fetchAllBrands(selectedBrand)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBrand])

  const fetchAllBrands2 = async (selectedBrand) => {
    // get data from database
    const q = query(collection(db, "admins"), where("uid", "==", currentUser))
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setSelectedBrand(doc.data().brandName)
    })

  };
  React.useEffect(() => {
    role === "brand" && fetchAllBrands2(selectedBrand)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBrand])

  async function checkEmailExists(email) {
    try {
      const q = query(collection(db, "admins"), where("email", "==", email)); // Query for documents where email matches
      const querySnapshot = await getDocs(q);
      // If any documents match the query, email exists
      return !querySnapshot.empty;
    } catch (error) {
      console.error("Error checking email: ", error);
      return false; // Return false in case of error
    }
  }

  

  const onAddBrandSale = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      if (!(await checkEmailExists(signupUser.email))) {
        const timestamp = Timestamp.fromDate(new Date()); 
        let docId = await addDoc(collection(db, 'admins'), {
          name: signupUser.name,
          email: signupUser.email,
          password: signupUser.password,
          role: "subadmin",
          rights: selectedRigts,
          timestamp: timestamp
        })
        brandsSaleNotificationOpen();
      } else {
        setErrorModal(true);
        setSignupCustomError("User already registered");
      }
    } catch (error) {
      setErrorModal(true);
      setSignupCustomError("something went wrong");
    } finally {
      setSignupUser({
        name: '',
        email: '',
        password: '',
        role: "subadmin",
        uid: '',
        rights: {}
      })
      brandsSaleModalClose();
      setLoading(false)
    }

  }
  const brandsSaleModalOpen = () => setBrandsModal(true);
  const brandsSaleModalClose = () => {
    setBrandsModal(false)
    setLoading(false)
    setError('')
  };
  const brandsSaleNotificationOpen = () => setBrandsSaleNotification(true);
  const brandsSaleNotificationClose = () => setBrandsSaleNotification(false);
  return (
    <>
      <MDSnackbar
        color="success"
        icon="check"
        title="Successfully Add"
        open={brandsSaleNotification}
        onClose={brandsSaleNotificationClose}
        close={brandsSaleNotificationClose}
      />

      <MDSnackbar
        color="warning"
        icon="check"
        title={signupCustomError}
        open={errorModal}
        onClose={errorModalClose}
        close={errorModalClose}
      />

      <BootstrapDialog
        onClose={brandsSaleModalClose}
        aria-labelledby="customized-dialog-title"
        open={brandsSaleModal}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={brandsSaleModalClose}>
          <Typography variant="h3" color="secondary.main" sx={{ pt: 1, textAlign: "center" }}>Add Sub-Admin</Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 2, maxWidth: "100%", display: "flex", direction: "column", justifyContent: "center" },
            }}
            noValidate
            autoComplete="off"
          >

            <div style={{ width: '500px' }}>
              <MDBox mb={2}>
                <MDInput
                  value={signupUser.name}
                  onChange={(e) => setSignupUser({
                    ...signupUser,
                    name: e.target.value
                  })}
                  type="name"
                  label="Name"
                  variant="standard"
                  autoComplete="off" 
                  required
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  value={signupUser.email}
                  onChange={(e) => setSignupUser({
                    ...signupUser,
                    email: e.target.value
                  })}
                  type="email"
                  label="Email"
                  variant="standard"
                  autoComplete="off"
                  required
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  value={signupUser.password}
                  onChange={(e) => setSignupUser({
                    ...signupUser,
                    password: e.target.value
                  })}
                  type="password"
                  label="Password"
                  variant="standard"
                  autoComplete="off"
                  required />
              </MDBox>

              <MDBox m={2}>
                <FormGroup>
                  <Grid container>
                    {allRights.map((right) => (
                      <Grid item key={right.value} xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedRigts[right.value] || false} // Set checked state based on state
                              onChange={handleCheckboxChange}
                              name={right.value}
                            />
                          }
                          label={right.label}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </MDBox>
            </div>
            <MDBox mt={1} p={1} sx={{ display: 'flex', direction: 'row', justifyContent: "center" }}>
              {loading ?
                <CircularProgress
                  size={30}
                  sx={{
                    color: green[500],
                  }}
                /> : <MDButton variant="contained" color="info" type="submit"
                  disabled={signupUser.email == "" || signupUser.password == "" || signupUser.name == ""  ? true : false}
                  onClick={onAddBrandSale}
                >Save</MDButton>
              }
            </MDBox>
          </Box>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </BootstrapDialog>

      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={2}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDBox display="flex" justifyContent="space-between" alignItems="center">
                      <MDTypography variant="h6" fontWeight="medium" color="white">
                        All Sub-Admins
                      </MDTypography>
                      <MDButton variant="gradient" color="light"
                        onClick={() => {
                          brandsSaleModalOpen()
                        }}
                      >
                        <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                        &nbsp;ADD Sub-Admin
                      </MDButton>
                    </MDBox>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default AddSale;
