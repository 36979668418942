import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getMessaging } from "firebase/messaging";
import { getStorage } from "firebase/storage";

import { getAnalytics, logEvent } from "firebase/analytics";

// Initialize Firebase
const app = initializeApp(
  {
    apiKey: "AIzaSyCzuRKbDCQmqWRH2I4TnZwgtySYOzoUqw4",
    authDomain: "mifever-de0ad.firebaseapp.com",
    projectId: "mifever-de0ad",
    storageBucket: "mifever-de0ad.appspot.com",
    messagingSenderId: "555512465168",
    appId: "1:555512465168:web:36c99c8583141dd5d20795",
    measurementId: "G-JY43M0GE8N"
  }
)
export const auth = getAuth(app)
export const db = getFirestore(app)
export const storage = getStorage(app)
export const messaging = getMessaging(app);

const analytics = getAnalytics(app);
logEvent(analytics, 'select_content', {
  content_type: 'image',
  content_id: 'P12453'
});