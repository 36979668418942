
//  admin panel React components
import MDBox from "components/MDBox";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

//  admin panel React example components
import * as React from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

//firestore
import { db } from "../../firebase"
import { collection, doc, onSnapshot, updateDoc } from "firebase/firestore";

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import MDSnackbar from "components/MDSnackbar";
import userHasRight from "constant";

function Notifications() {
  const [data, setData] = React.useState([])

  const [settingId, setSettingId] = React.useState("");

  const [privacyPolicyState, setPrivacyPolicyState] = React.useState();
  const [termsAndConditionsState, setTermsAndConditionsState] = React.useState("");
  const [communityGuidelinesState, setCommunityGuidelinesState] = React.useState("");

  React.useEffect(() => {
    const fetchData = onSnapshot(collection(db, "Settings"),
      (snapshot) => {
        const notificationList = snapshot.docs.map((items) => {
          return { id: items.id, ...items.data() }
        })

        if (notificationList && notificationList.length > 0) {
          setSettingId(notificationList[0].id)
          const privacyblocksFromHTML = notificationList[0].privacyPolicy;
          setPrivacyPolicyState(privacyblocksFromHTML);
          const termsAndConditionblocksFromHTML = notificationList[0].termsAndConditions;
          setTermsAndConditionsState(termsAndConditionblocksFromHTML);
          const communityGuidelinesHTML = notificationList[0].communityGuidelines;
          setCommunityGuidelinesState(communityGuidelinesHTML);
        }

        setData(notificationList)
      },
      (error) => {
        console.log("error == ", error)
      })
    return () => {
      fetchData()
    }
  }, [])

  const [brandsNotification, setBrandsNotification] = React.useState(false)

  return (
    <DashboardLayout>

      <MDSnackbar
        color="success"
        icon="check"
        title="Setting Content Updated Successfully"
        open={brandsNotification}
        onClose={() => setBrandsNotification(false)}
        close={() => setBrandsNotification(false)}
      />

      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={2} mb={1} lineHeight={0}>
          <div style={{ border: '1px solid #ccc', borderRadius: '4px', minHeight: '600px', padding: '10px', backgroundColor: '#f7f7f7' }}>
            <MDTypography mt={2} mb={2} variant="caption" fontWeight="medium">Terms and condition</MDTypography>
            <ReactQuill
              theme="snow"
              style={{ height: '520px' }}
              placeholder="Content"
              value={termsAndConditionsState}
              onChange={(value) => {
                setTermsAndConditionsState(value)
              }}
            />
          </div>
          <div style={{ border: '1px solid #ccc', borderRadius: '4px', minHeight: '600px', padding: '10px', backgroundColor: '#f7f7f7', marginTop: '50px' }}>
            <MDTypography mt={2} mb={2} variant="caption" fontWeight="medium">Privacy Policy</MDTypography>
            <ReactQuill
              theme="snow"
              style={{ height: '520px' }}
              placeholder="Content"
              value={privacyPolicyState}
              onChange={(value) => {
                setPrivacyPolicyState(value)
              }}
            />
          </div>

          <div style={{ border: '1px solid #ccc', borderRadius: '4px', minHeight: '600px', padding: '10px', backgroundColor: '#f7f7f7', marginTop: '50px' }}>
            <MDTypography mt={2} mb={2} variant="caption" fontWeight="medium">Community Guidelines</MDTypography>
            <ReactQuill
              theme="snow"
              style={{ height: '520px' }}
              placeholder="Content"
              value={communityGuidelinesState}
              onChange={(value) => {
                setCommunityGuidelinesState(value)
              }}
            />
          </div>

          {
            userHasRight('UPDATE_SETTING') == true && (
              <MDBox
                display="flex"
                justifyContent="end"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
                mb={5} mt={2}>
                <MDButton variant="gradient" color="info" size="small" onClick={() => {
                  updateDoc(doc(db, "Settings", settingId), {
                    privacyPolicy: privacyPolicyState,
                    termsAndConditions: termsAndConditionsState,
                    communityGuidelines: communityGuidelinesState
                  })
                    .then(() => {
                      setBrandsNotification(true)
                      console.log("Document successfully updated!");
                    })
                    .catch((error) => {
                      console.error("Error updating document: ", error);
                    });

                }} >Save</MDButton>
              </MDBox>
            )
          }


        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Notifications;
