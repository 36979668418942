const convertUTCtoLocal = (utcDate) => {
  if (utcDate.includes("Z") || utcDate.includes("T")) {
    // const date = new Date(utcDate);
    // const dateFormatter = new Intl.DateTimeFormat();
    // return dateFormatter.format(date);
    return utcDate;
  } else {
    return utcDate;
  }
};

export default convertUTCtoLocal;