// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Users from "layouts/users";
import AddSale from "layouts/addSale";
import Banks from "layouts/banks";
import Categories from "layouts/categories";
import Carousels from "layouts/carousels";
import Discounts from "layouts/discounts";
import Notifications from "layouts/notifications/Notifications";
import SendNotifications from "layouts/notifications/SendNotifications";
import Signup from "layouts/authentication/users/Signup"

//auth routes
import UsersDetail from "layouts/users/components/Detail"
import BanksDetail from "layouts/banks/components/Detail"
import SalesDetail from "layouts/addSale/components/Detail"
import CarouselsDetail from "layouts/carousels/components/Detail"
import DiscountsDetail from "layouts/discounts/components/Detail"

// @mui icons
import DashboardIcon from '@mui/icons-material/Dashboard';
import StoreIcon from '@mui/icons-material/Store';
import InventoryIcon from '@mui/icons-material/Inventory';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CategoryIcon from '@mui/icons-material/Category';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import LoginIcon from '@mui/icons-material/Login';
import Icon from "@mui/material/Icon";
import * as React from 'react'
import { Navigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "context/AuthContext";

const AdminAuthRoutes = ({ children }) => {
  const { role } = useContext(AuthContext)
  return role === "admin" ? children : <Navigate to="/login" />
}
const BrandAuthRoutes = ({ children }) => {
  const { role } = useContext(AuthContext)
  return role === "brand" ? children : <Navigate to="/login" />
}
const BankAuthRoutes = ({ children }) => {
  const { role } = useContext(AuthContext)
  return role === "bank" ? children : <Navigate to="/login" />
}

const routes = [
  {
    routeRole: "admin",
    type: "collapse",
    name: "Dashboard",
    key: "admin/dashboard",
    icon: <DashboardIcon />,
    route: "/admin/dashboard",
    component: <AdminAuthRoutes><Dashboard /></AdminAuthRoutes>,
  },
  {
    routeRole: "admin",
    type: "collapse",
    name: "Users",
    key: "admin/users",
    icon: <StoreIcon />,
    route: "/admin/users",
    component: <AdminAuthRoutes><Users /></AdminAuthRoutes>,
  },
  {
    routeRole: "admin",
    type: "collapse",
    name: "Add Sub-Admin",
    key: "admin/addSubAdmin",
    icon: <InventoryIcon />,
    route: "/admin/addSubAdmin",
    component: <AdminAuthRoutes><AddSale /></AdminAuthRoutes>,
  },
  {
    routeRole: "admin",
    type: "collapse",
    name: "News and Articles",
    key: "admin/newsAndArticles",
    icon: <AccountBalanceIcon />,
    route: "/admin/newsAndArticles",
    component: <AdminAuthRoutes><Banks /></AdminAuthRoutes>,
  },
  {
    routeRole: "admin",
    type: "collapse",
    name: "Analytics",
    // key: "admin/laws",
    key: "analytics",
    icon: <CategoryIcon />,
    route: "https://mixpanel.com/login/",
    component: <AdminAuthRoutes><Categories /></AdminAuthRoutes>,
  },
  {
    routeRole: "admin",
    type: "collapse",
    name: `Policies`,
    key: "admin/settings",
    icon: <NotificationsActiveIcon />,
    route: "/admin/settings",
    component: <AdminAuthRoutes><Notifications /></AdminAuthRoutes>,
  },





  // {
  //   routeRole: "admin",
  //   type: "collapse",
  //   name: "Carousels",
  //   key: "admin/carousels",
  //   icon: <ViewCarouselIcon />,
  //   route: "/admin/carousels",
  //   component: <AdminAuthRoutes><Carousels /></AdminAuthRoutes>,
  // },
  // {
  //   routeRole: "admin",
  //   type: "collapse",
  //   name: "Discounts",
  //   key: "admin/discounts",
  //   icon: <Icon>discounts</Icon>,
  //   route: "/admin/discounts",
  //   component: <AdminAuthRoutes><Discounts /></AdminAuthRoutes>,
  // },
  // {
  //   routeRole: "admin",
  //   type: "collapse",
  //   name: "Signup",
  //   icon: <LoginIcon />,
  //   route: "/admin/signup",
  //   component: <AdminAuthRoutes><Signup /></AdminAuthRoutes>,
  // },
  // {
  //   routeRole: "brand",
  //   type: "collapse",
  //   name: "Demo Dashboard",
  //   key: "brand/dashboard",
  //   icon: <DashboardIcon />,
  //   route: "/brand/dashboard",
  //   component: <BrandAuthRoutes><Dashboard /></BrandAuthRoutes>
  // },
  // {
  //   routeRole: "brand",
  //   type: "collapse",
  //   name: "Add Sale",
  //   key: "brand/addSale",
  //   icon: <InventoryIcon />,
  //   route: "/brand/addSale",
  //   component: <BrandAuthRoutes><AddSale /></BrandAuthRoutes>,
  // },
  // {
  //   routeRole: "brand",
  //   type: "collapse",
  //   name: `Send Notifications`,
  //   key: "brand/sendNotifications",
  //   icon: <NotificationAddIcon />,
  //   route: "/brand/sendNotifications",
  //   component: <BrandAuthRoutes><SendNotifications /></BrandAuthRoutes>,
  // },
  // {
  //   routeRole: "bank",
  //   type: "collapse",
  //   name: "Dashboard",
  //   key: "bank/dashboard",
  //   icon: <DashboardIcon />,
  //   route: "/bank/dashboard",
  //   component: <BankAuthRoutes><Dashboard /></BankAuthRoutes>,
  // },
  // {
  //   routeRole: "bank",
  //   type: "collapse",
  //   name: "Discounts",
  //   key: "bank/discounts",
  //   icon: <Icon>discounts</Icon>,
  //   route: "/bank/discounts",
  //   component: <BankAuthRoutes><Discounts /></BankAuthRoutes>,
  // },
  // {
  //   routeRole: "bank",
  //   type: "collapse",
  //   name: `Send Notification`,
  //   key: "bank/sendNotificationst",
  //   icon: <NotificationAddIcon />,
  //   route: "/bank/sendNotifications",
  //   component: <BankAuthRoutes><SendNotifications /></BankAuthRoutes>,
  // },
]

const authRoutes = [
  {
    routeRole: "admin",
    type: "authRoutes",
    route: "/admin/users/detail/:id",
    component: <AdminAuthRoutes><UsersDetail /></AdminAuthRoutes>,
  },
  {
    routeRole: "admin",
    type: "authRoutes",
    route: "/admin/banks/detail/:id",
    component: <AdminAuthRoutes><BanksDetail /></AdminAuthRoutes>,
  },
  {
    routeRole: "admin",
    type: "authRoutes",
    route: `/admin/addSale/detail/:id`,
    component: <AdminAuthRoutes><SalesDetail /></AdminAuthRoutes>,
  },
  {
    routeRole: "admin",
    type: "authRoutes",
    route: "/admin/carousels/detail/:id",
    component: <AdminAuthRoutes><CarouselsDetail /></AdminAuthRoutes>,
  },
  {
    routeRole: "admin",
    type: "authRoutes",
    route: "/admin/discounts/detail/:id",
    component: <AdminAuthRoutes><DiscountsDetail /></AdminAuthRoutes>,
  },
  // {
  //   routeRole: "brand",
  //   type: "authRoutes",
  //   route: `/brand/addSale/detail/:id`,
  //   component: <BrandAuthRoutes><SalesDetail /></BrandAuthRoutes>,
  // },
  {
    routeRole: "bank",
    type: "authRoutes",
    route: "/bank/discounts/detail/:id",
    component: <BankAuthRoutes><DiscountsDetail /></BankAuthRoutes>,
  },
]
export default routes;
export { authRoutes }
