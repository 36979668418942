// Admin panel React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import * as React from "react"
import { Button, CircularProgress, OutlinedInput, InputAdornment, Icon, IconButton, DialogActions, Dialog, DialogTitle, DialogContent, DialogContentText, Typography, Box, TextField, InputLabel, FormControl, Select, MenuItem } from '@mui/material'
import { green } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Admin panel React context
import { useMaterialUIController } from "context";

//firestore
import { db } from "../.../../../../firebase"
import { onSnapshot, collection, deleteDoc, doc, getDoc, setDoc, orderBy } from "firebase/firestore";
import userHasRight from "constant";

//modal Styles
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function Data() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [data, setData] = React.useState([])
  const [deleteAlert, setDeleteAlert] = React.useState(false);
  const [categoryModal, setCategoryModal] = React.useState(false);
  const [categoryNotification, setCategoryNotification] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [deleteDataId, setDeleteDataId] = React.useState('');
  const [updateDataId, setUpdateDataId] = React.useState('');
  const [categoryValue, setCategoryValue] = React.useState('')
  const [categoryValueDel, setCategoryValueDel] = React.useState('')
  const [dbCategoryData, setDbCategoryData] = React.useState({
    country: '',
    content: '',
  });

  const [showContentModal, setShowContentModal] = React.useState(false);
  const [lawContent, setLawContent] = React.useState("");

  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Ivory Coast",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Korea",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe"
  ];

  React.useEffect(() => {
    const fetchData = onSnapshot(collection(db, "laws"),

      (snapshot) => {
        const catData = snapshot.docs.map((items) => {
          return { id: items.id, ...items.data() }
        })
        catData.sort((a, b) => b.timestamp - a.timestamp);
        setData(catData)
      },
      (error) => {
        console.log("error == ", error.code)
      })
    return () => {
      fetchData()
    }
  }, [])

  const fetchAllCollectionsCategories = async () => {
    // get data from database
    try {
      const getCategory = await getDoc(doc(db, "laws", updateDataId));
      if (getCategory.exists()) {
        const item = getCategory.data();
        setDbCategoryData({
          country: item.country,
          content: item.content
        })
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.log('error == ', error)
    }
  };
  React.useEffect(() => {
    fetchAllCollectionsCategories()
  }, [updateDataId])

  const deleteById = async () => {
    try {
      if (deleteDataId) {
        const reference = doc(db, 'laws', deleteDataId)
        await deleteDoc(reference)
      }
      deleteAlertClose()
      setDeleteDataId('')
    } catch (error) {
      console.log('error == ', error)
    }
  };

  const onUpdateCategory = async (e) => {
    e.preventDefault()
    const updateData = {
      country: dbCategoryData.country,
      content: dbCategoryData.content
    }
    try {
      setLoading(true)
      if (updateDataId) {
        const categoryDocRef = doc(db, "laws", updateDataId)
        await setDoc(categoryDocRef, updateData, { merge: true })
        setDbCategoryData({
          country: '',
          content: ''
        })
      }
      categoryModalClose()
      categoryNotificationOpen()
      setUpdateDataId('')
    }
    catch (error) {
      setError(error.code)
      setLoading(false)
    }
  }

  const SR_NO = ({ srNo }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography variant="body2" fontWeight="small">
          {srNo}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
  const CATEGORY_NAME = ({ name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          <span style={{fontWeight: '400', fontSize: '14px'}}>{name}</span>
        </MDTypography>
      </MDBox >
    </MDBox>
  );

  const deleteAlertOpen = (items) => {
    setDeleteAlert(true)
    setDeleteDataId(items.id)
    setCategoryValueDel(items.name)
  }
  const deleteAlertClose = () => {
    setDeleteAlert(false)
    setDeleteDataId('')
    setCategoryValueDel('')
  };
  const categoryModalOpen = (items) => {
    setCategoryModal(true)
    setUpdateDataId(items.id)
    setCategoryValue(items.country)
  };
  const categoryModalClose = () => {
    setCategoryModal(false)
    setUpdateDataId('')
    setCategoryValue('')
    setLoading(false)
    setError('')
  };
  const categoryNotificationOpen = () => setCategoryNotification(true);
  const categoryNotificationClose = () => setCategoryNotification(false);
  return {
    columns: [
      { Header: "SR NO#", accessor: "srNo", width: '10%', align: "left" },
      { Header: "Country", accessor: "country", align: "left" },
      { Header: "Action", accessor: "action", align: "right" }
    ],
    rows: [...data.map((items, index) => {
      return ({
        srNo: <SR_NO srNo={index + 1} />,
        country: <CATEGORY_NAME name={items.country} />,
        action: (<>
          <MDSnackbar
            color="success"
            icon="check"
            title="Successfully Update"
            open={categoryNotification}
            onClose={categoryNotificationClose}
            close={categoryNotificationClose}
          />
          <Dialog
            open={deleteAlert}
            onClose={deleteAlertClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Alert"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={deleteAlertClose}>Cancel</Button>
              <Button sx={{ color: 'error.main' }} onClick={deleteById}>
                Delete
              </Button>
            </DialogActions>
          </Dialog>

          <BootstrapDialog
            onClose={categoryModalClose}
            aria-labelledby="customized-dialog-title"
            open={categoryModal}
          >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={categoryModalClose}>
              <Typography variant="h3" color="secondary.main" sx={{ pt: 1, textAlign: "center" }}>Edit Law</Typography>
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 2, maxWidth: "100%", display: "flex", direction: "column", justifyContent: "center" },
                }}
                noValidate
                autoComplete="off"
              >
                <Box sx={{ maxWidth: "100%", m: 2 }}>
                  <FormControl fullWidth>

                    <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      sx={{ height: "2.8rem", marginBottom: '20px' }}
                      label="Select Country"
                      style={{ width: '500px' }}
                      value={dbCategoryData.country}
                      onChange={(e) => {
                        setDbCategoryData({
                          ...dbCategoryData,
                          country: e.target.value
                        })
                      }}
                    >
                      {countries.map((item) => {
                        return (
                          <MenuItem key={item} value={item}>{item}</MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <ReactQuill
                      theme="snow"
                      style={{ height: '250px', width: '500px' }}
                      placeholder="Content"
                      value={dbCategoryData.content}
                      onChange={(value) => {
                        setDbCategoryData({
                          ...dbCategoryData,
                          content: value
                        })
                      }}
                    />
                  </FormControl>
                </Box>
                {error === '' ? null :
                  <MDBox mb={2} p={1}>
                    <TextField
                      error
                      id="standard-error"
                      label="Error"
                      InputProps={{
                        readOnly: true,
                        sx: {
                          "& input": {
                            color: "red",
                          }
                        }
                      }}
                      // defaultValue="Invalid Data!"
                      value={error}
                      variant="standard"
                    />
                  </MDBox>}
              </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
              {loading ?
                <CircularProgress
                  size={30}
                  sx={{
                    color: green[500],
                  }}
                /> : <MDButton variant="contained" color="info" type="submit"
                  onClick={onUpdateCategory}
                >Update</MDButton>
              }
            </DialogActions>
          </BootstrapDialog>


          <BootstrapDialog
            onClose={() => setShowContentModal(false)}
            aria-labelledby="customized-dialog-title"
            open={showContentModal}
          >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setShowContentModal(false)}>
              <Typography variant="h3" color="secondary.main" sx={{ pt: 1, textAlign: "center" }}>Law Content</Typography>
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 2, maxWidth: "100%", display: "flex", direction: "column", justifyContent: "center" },
                }}
                noValidate
                autoComplete="off"
              >
                <div style={{ height: '250px', width: '500px' }} dangerouslySetInnerHTML={{ __html: lawContent }}></div>
              </Box>
            </DialogContent>
          </BootstrapDialog>

          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>

            <MDBox mr={1}>
              <Button style={{ color: 'white' }} variant="contained" onClick={() => {
                setLawContent(items.content);
                setShowContentModal(true)

              }} >View</Button>
            </MDBox>

            {
              userHasRight('DELETE_LAW') == true && (
                <MDBox mr={1}>
                  <MDButton variant="text" color="error" onClick={() => deleteAlertOpen(items)}>
                    <Icon>delete</Icon>&nbsp;delete
                  </MDButton>
                </MDBox>
              )
            }

            {
              userHasRight('UPDATE_LAW') == true && (
                <MDButton variant="text" color={darkMode ? "white" : "dark"} onClick={() => categoryModalOpen(items)}>
                  <Icon>edit</Icon>&nbsp;edit
                </MDButton>

              )
            }

          </MDBox></>),
      })
    })]
  }
}
export default Data