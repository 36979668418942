


export const sendMail = async (emails, subject, body) => {
    try {
        const response = await fetch(`https://mifiver.akoode.net/backend/mail.php?subject=${subject}&body=${body}&emails=${emails.join(",")}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
};

export default sendMail;